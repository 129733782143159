import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './Home'
import Details from './Details'
require('dotenv').config();

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" render={(props) => (
            <Home {...props} />
          )} />
          <Route path="/details/:id" render={(props) => (
            <Details {...props} />
          )} />
        </Switch>
      </Router>
    );
  }
}
export default App
