import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Modal from 'react-bootstrap/Modal'
import Masonry from 'react-masonry-css'

class Home extends React.Component {
  state = {
    groups: [],
    museums: [],
    index: 0,
    isLoaderStatus: false,
    popupShow: false,
    popupContent: '',
    pscImages: [],
    settings: null
  }


  componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    const api_url = process.env.REACT_APP_API_URL
    this.setState({ isLoaderStatus: true })
    await fetch(api_url + "groups/")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({ groups: result })
        },
        (error) => {
          console.log(error)
        }
      )
    await fetch(api_url + "setting/")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({ settings: result })
        },
        (error) => {
          console.log(error)
        }
      )
    await fetch(api_url + "images/")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({ pscImages: result })
        },
        (error) => {
          console.log(error)
        }
      )
    await fetch(api_url + "museums")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({ museums: result })
        },
        (error) => {
          console.log(error)
        }
      )
    this.setState({ isLoaderStatus: false })

  }
  handleClosePopup = () => {
    this.setState({ popupContent: '', popupShow: false })
  }

  handleShowPopup = (text) => {
    this.setState({ popupContent: text, popupShow: true })
  }

  render() {
    const { groups, popupShow, pscImages, popupContent, settings, museums } = this.state
    const url = process.env.REACT_APP_APP_URL + 'museum/'
    let landingSliderTitle
    if (settings) {
      landingSliderTitle = settings.find(data => data.key === 'landingSlierTitle')
      landingSliderTitle = landingSliderTitle.value
    }
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 1901 },
        items: 6,
        slidesToSlide: 1
      },
      desktop: {
        breakpoint: { max: 1900, min: 992 },
        items: 4,
        slidesToSlide: 1
      },
      tablet: {
        breakpoint: { max: 991, min: 464 },
        items: 2,
        slidesToSlide: 1
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
      }
    };
    const breakpointColumnsObj = {
      3000:6,
      1901:6,
      1500:4,
      992: 3,
      768: 2,
      500: 1
    };

    return (
      <div className="main " >
        <LoadingOverlay className='pleaseLoader' active={this.state.isLoaderStatus} spinner text='Please wait' />
        <Modal show={popupShow} onHide={this.handleClosePopup}>
          <div className='popup'>
            <button className="close" onClick={this.handleClosePopup}>&times;</button>
          </div>
          <Modal.Body className='soon'>
            <h2>PixStoriCard™️</h2>
            <div dangerouslySetInnerHTML={{ __html: popupContent }} />
          </Modal.Body>
        </Modal>
        <div className="logo">
          <div className="container">
            <a href="https://www.pixstoriplus.com/">
              <img src="images/logo.png" alt="PixStori" />
            </a>
          </div>
          <div className="help-icon">
            <a onClick={() => this.handleShowPopup("<p>Share images from the world’s great museums along with a personal message in your own voice – for free. Choose a premade card from the samples or the Groups. You can always edit the text caption to say just what you want. Then add your personal message – in your voice or text or both – and send your PixStoriCard.</br>Or make your own card. Choose a museum and search for an image. Then add your personal message in your own voice -- or text or both -- and send your PixStoriCard. Your PixStoriCard will be private. Only people with the email link will see it.<br/>To get started,choose a premade card or choose a museum and search.</br>Send a PixStoriCard to someone you care about.</p>")}>
              <img src="images/help-icon.png" alt="" />
            </a>
          </div>
        </div>
        <div className="what-is-it">
          <h5>What is it?</h5>
          <img src="images/what-is-it.png" alt="" />
        </div>
        <div className="card-carousel" id="card-section">
          <div className='card-carousel-title'>
            <h5><span>Send one now </span>or explore more below</h5>
            {/* <span>
              <a onClick={() => this.handleShowPopup("Lets you share images from the world’s great museums along with a personal message in your own voice – for free. Choose a museum image and then add your personal message in your own voice -- or text or both -- and send your  PixStoriCard. Your PixStoriCard will be private. Only people with the email link will  see it. To get started, choose a museum.<br /> Choose a Pix, Add a Stori and Send a PixStoriCard to someone you care about.</p>")}>
                <img src="images/help-icon.png" alt="" />
              </a>
            </span> */}
            <h6>{landingSliderTitle}</h6>
          </div>
          <div className="owl-carousel owl-theme">
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={false}
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
              responsive={responsive}
            >
              {pscImages.map(image => (
                <a key={image._id} target="_blank" rel="noopener noreferrer" href={url + image.museum + '/' + image.groupId + '/' + image._id} >
                  <div className='item' style={{ border: '15px solid', borderColor: image.border, borderRadius: '5px' }}>
                    <div className="item-info">
                      <div className="item-card-logo"><img src="/images/logo.png" alt="" /></div>
                      <div className="item-title">{image.title}</div>
                      <img src={image.imageUrl} alt="" />
                      <div className="item-btm-img"><img src="/images/mic.jpg" alt="" /></div>
                    </div>
                  </div>
                </a>
              ))}
            </Carousel>
          </div></div>
        <div className="content-museum">
          <div className="choose-museum-title">
            <h5>Search Museum’s</h5>
            <h5>Open Access Collections</h5>
            {/* <a onClick={() => this.handleShowPopup("Lets you share images from the world’s great museums along with a personal message in your own voice – for free. Choose a museum image and then add your personal message in your own voice -- or text or both -- and send your  PixStoriCard. Your PixStoriCard will be private. Only people with the email link will  see it. To get started, choose a museum.<br /> Choose a Pix, Add a Stori and Send a PixStoriCard to someone you care about.</p>")}>
              <img src="images/help-icon.png" alt="" />
            </a> */}
          </div>
          <div className="choose-museum">
            <select id="Choose" onChange={(e) => { window.location = e.target.value }} className="classic" name="" >
              <option value="" >Choose Museum</option>
              {museums.map(museum => (
                < option key={museum.title} value={url + museum.slug} > {museum.title}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="group-img">
          <h5>or browse more Card groups</h5>
          <div className="container-fluid">
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column">
              {groups.map(group => (
                <Link key={group._id} title='Click to see more ready made card for this group' to={`/details/${group._id}`}>
                  <div className="gi-row">
                    <div className="gi-grid">
                      <div className="gi-gird-top">
                        <div className="grid-title">
                          <h3>{group.title}</h3>
                        </div>
                        <div className="user-profile">
                          <span>{group.owner}</span>
                          <img src={group.profileImage} alt="" />
                        </div>
                      </div>
                      <div className='grid-description'>
                        <p>{group.description}</p>
                      </div>
                      {group.thumbnails.map(thumbnail => (
                        <div key={thumbnail._id} className='groupImageLink'>
                          <div className="gi-content">
                            <img src={thumbnail.imageUrl} alt="" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Link>
              ))}
            </Masonry>
          </div>
        </div>
        <footer className="footer">
          <div className="container">
            <div className="sign-up">
              <h3>Be a part of the PixStori</h3>
            </div>
            <div className="copy-right">
              <p>Copyright © {new Date().getFullYear()} Talking Pictures LLC</p>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
export default Home
