import React from 'react';
import './App.css';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import Masonry from 'react-masonry-css'

class Details extends React.Component {
  state = {
    title: '',
    description: '',
    owner: '',
    profileImage: '',
    thumbnails: [],
    museums: [],
    isLoaderStatus: false,
    id: '',
    popupShow: false,
    popupContent: ''
  }


  async componentDidMount() {
    await this.fetchData()
  }

  fetchData = async () => {
    const api_url = process.env.REACT_APP_API_URL
    this.setState({ isLoaderStatus: true })
    const { id } = this.props.match.params
    await fetch(api_url + "group/" + id)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            id: id,
            isLoaderStatus: false,
            title: result.title,
            description: result.description,
            owner: result.owner,
            profileImage: result.profileImage,
            thumbnails: result.thumbnails
          })
        },
        (error) => {
          console.log(error)
        }
      )
    await this.setState({ isLoaderStatus: false })

    await fetch(api_url + "museums/")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            museums: result, isLoaderStatus: false,
          })
        },
        (error) => {
          console.log(error)
        }
      )
    console.log(this.state)

  }
  handleClosePopup = () => {
    this.setState({ popupContent: '', popupShow: false })
  }

  handleShowPopup = (text) => {
    this.setState({ popupContent: text, popupShow: true })
  }


  render() {
    const { title, description, owner, profileImage, popupShow, popupContent, id, thumbnails, museums } = this.state
    const url = process.env.REACT_APP_APP_URL + 'museum/'
    return (
      <div className="main ">
        <LoadingOverlay className='pleaseLoader' active={this.state.isLoaderStatus} spinner text='Please wait' />
        <div className="logo">
          <div className="container">
            <a href="https://www.pixstoriplus.com/">
              <img src="../images/logo.png" alt="PixStori" />
            </a>
          </div>
        </div>
        <Modal show={popupShow} onHide={this.handleClosePopup}>
          <div className='popup'>
            <button className="close" onClick={this.handleClosePopup}>&times;</button>
          </div>
          <Modal.Body className='soon'>
            <h2>PixStoriCard™️</h2>
            <div dangerouslySetInnerHTML={{ __html: popupContent }} />
          </Modal.Body>
        </Modal>
        <div className="content-museum bottom">
          <div className="choose-museum-title">
            <h5>Search Museum’s</h5>
            <h5>Open Access Collections</h5>
          </div>
          <div className="choose-museum">
            <select id="Choose" onChange={(e) => { window.location = e.target.value }} className="classic" name="" >
              <option value="" >Choose Museum</option>
              {museums.map(museum => (
                < option key={museum.title} value={url + museum.slug} > {museum.title}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="group-img">
          <div className="container">
            <div className="back-icon">
              <Link title='Back to home page' to="/">
                <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
              </Link>
            </div>
            <div className="gi-row w-100">
              <div className="gi-grid-title">
                <h3>{title}</h3>
                <p>{description}</p>
              </div>
              <div className="user-profile">
                <span>{owner}</span>
                <img src={profileImage} alt="" />
              </div>
              <div className="gi-grid">
                <Masonry
                  breakpointCols={3}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column">
                  {thumbnails.map(thumbnail => (
                    <a className='thumbnailBox' target="_blank" rel="noopener noreferrer" key={thumbnail._id} href={url + thumbnail.museum + '/' + id + '/' + thumbnail._id} >
                      <div className="gi-grid-detail-content">
                        <h3>{thumbnail.title}</h3>
                        <img src={thumbnail.imageUrl} alt="" />
                      </div>
                    </a>
                  ))}</Masonry>
              </div>
            </div>
          </div></div>
        <footer className="footer">
          <div className="container">
            <div className="sign-up">
              <h3>Be a part of the PixStori</h3>
            </div>
            <div className="copy-right">
              <p>Copyright © {new Date().getFullYear()} Talking Pictures LLC</p>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
export default Details
